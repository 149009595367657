<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>會員管理中心</b-breadcrumb-item>
            <b-breadcrumb-item active>會員管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">會員管理</h4>
      <div class="row my-3">
        <div class="col-12 col-xl-6">
          <b-form inline>
            <b-form-select
              v-model="query.merchant_hashid"
              :options="merchants"
              @change="handleChangeMerchant"
              :disabled="merchants.length <= 1"
            ></b-form-select>
            <b-form-select
              v-if="merchants.length > 1"
              v-model="query.is_binded"
              :options="bindedOptions"
              @change="handleChangeMerchant"
              :disabled="query.merchant_hashid === 'cross'"
              class="ml-2"
            ></b-form-select>
          </b-form>
        </div>
        <!-- <div class="col-12 col-xl-3"></div> -->
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <router-link
            v-if="$permissions.has($permissions.consts.CUSTOMER_LIST_IMPORT)"
            :to="{ name: 'CustomerListImport' }"
            class="btn flex-shrink-0 mb-2 ml-2 btn-inverse-info"
            target="_self"
          >
            <span class="mdi mdi-cloud-upload"></span> 批次上傳
          </router-link>
          <b-dropdown class="flex-shrink-0 mb-2 ml-2" variant="inverse-info">
            <template #button-content>
              <span class="mdi mdi-download"></span> 資料匯出
            </template>
            <b-dropdown-item @click="exportCustomers('excel')"
              >下載 EXCEL</b-dropdown-item
            >
            <b-dropdown-item @click="exportCustomers('csv')"
              >下載 CSV</b-dropdown-item
            >
          </b-dropdown>
          <b-input-group class="mb-2 mb-xl-0 ml-0 ml-xl-4">
            <b-form-input
              v-model="query.keyword"
              @keyup.enter="handlePageReset"
              placeholder="搜尋姓名、手機、Email、識別編號"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="customers"
            :fields="fields"
            :busy="isFetchCustomerList"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(avatar_url)="data">
              <div class="d-flex align-items-center justify-content-center">
                <router-link
                  :to="{
                    name: 'CustomerDetailView',
                    params: { customerID: data.item.id },
                  }"
                >
                  <b-avatar
                    :src="data.item.avatar_url"
                    variant="secondary"
                    size="2rem"
                  ></b-avatar>
                </router-link>
              </div>
            </template>
            <template #cell(name)="data">
              <router-link
                :to="{
                  name: 'CustomerDetailView',
                  params: { customerID: data.item.id },
                }"
              >
                {{ hiddenString(data.item.name, 20) || "(無法辨識)" }}
              </router-link>
            </template>
            <template #cell(merchant_types)="data">
              <template v-if="query.merchant_hashid === 'cross'">
                <div :id="`${data.item.id}-tooltip`">
                  <b-badge
                    v-if="data.item.merchant_type_name"
                    variant="primary"
                    class="mr-1"
                    >{{ data.item.merchant_type_name.name }}</b-badge
                  >
                  <div
                    v-for="merchant in data.item.cross_merchants"
                    :key="merchant.id"
                  >
                    <br />
                    <b-badge variant="primary" class="mr-1">{{
                      merchant.merchant_type_name
                    }}</b-badge>
                  </div>
                </div>
                <b-popover
                  :target="`${data.item.id}-tooltip`"
                  triggers="hover focus click"
                >
                  <div class="text-left">
                    <ul class="my-0">
                      <li v-if="data.item.merchant_type_name">
                        {{ data.item.merchant_type_name.name }}
                      </li>
                      <div
                        v-for="merchant in data.item.cross_merchants"
                        :key="merchant.id"
                      >
                        <li v-if="merchant.merchant_name">
                          {{ merchant.merchant_name }}
                        </li>
                      </div>
                    </ul>
                  </div>
                </b-popover>
              </template>
              <template v-else>
                <b-badge
                  v-if="data.item.merchant_type_name"
                  variant="primary"
                  class="mr-1"
                  :id="`single-merchant-${data.item.id}`"
                  >{{ data.item.merchant_type_name.type_name }}
                </b-badge>
                <b-popover
                  :target="`single-merchant-${data.item.id}`"
                  triggers="hover focus click"
                >
                  <ul>
                    <li>
                      {{ data.item.merchant_type_name.name }}
                    </li>
                  </ul>
                </b-popover>
                <b-badge
                  v-if="data.item.cross_merchants.length"
                  variant="primary"
                  :id="`cross-merchants-${data.item.id}`"
                >
                  其他通路
                </b-badge>
                <b-popover
                  :target="`cross-merchants-${data.item.id}`"
                  triggers="hover focus click"
                >
                  <div class="text-left">
                    <ul class="my-0">
                      <li
                        v-for="merchant in data.item.cross_merchants"
                        :key="merchant.id"
                      >
                        {{
                          `${merchant.merchant_type_name} (${merchant.merchant_name})`
                        }}
                      </li>
                    </ul>
                  </div>
                </b-popover>
              </template>
            </template>
            <template #cell(actions)="data">
              <b-dropdown variant="secondary">
                <template slot="button-content">
                  <i class="mdi mdi-menu"></i>
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'CustomerDetailView',
                    params: { customerID: data.item.id },
                  }"
                >
                  檢視
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>

      <CustomPagination
        :currentPage="query.page"
        :totalRows="totalRows"
        :perPage="query.per_page"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { BAvatar } from "bootstrap-vue";
import customerApi from "@/apis/customer";
import moment from "moment";
import axios from "axios";

import "bootstrap-vue/dist/bootstrap-vue.css";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { paginationMixin } from "@/mixins/pagination";

export default {
  components: { CustomPagination, BAvatar },
  mixins: [paginationMixin],
  data() {
    const baseFields = [
      { key: "avatar_url", label: "" },
      { key: "name", label: "顯示名稱" },
      { key: "outer_code", label: "識別編號" },
      { key: "mobile_phone", label: "手機" },
      { key: "email", label: "Email" },
      {
        key: "birthday",
        label: "生日",
        formatter: (value) => {
          return value ? moment(value).format("YYYY-MM-DD") : "";
        },
      },
      { key: "merchant_types", label: "來源通路" },
    ];

    return {
      totalRows: 0,
      isFetchCustomerList: false,
      bindedOptions: [
        { value: null, text: "無論會員有無綁定" },
        { value: true, text: "已綁定" },
        { value: false, text: "未綁定" },
      ],
      merchants: [],
      customers: [],
      merchant: {},
      baseFields: baseFields,
      fields: baseFields,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        is_binded: null,
        merchant_hashid: null,
      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      defaultMerchantType: (state) => state.defaultMerchantType,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },

  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      const defaultQuery = {
        is_binded: null,
        merchant_hashid: null,
      };
      this.query = { ...defaultQuery, ...to.query };
      this.getCustomerList();
    },
  },

  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    this.init();
  },

  methods: {
    async init() {
      await this.getMerchants();
      await this.getCustomerList();
      this.initialized = true;
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
    async getMerchants() {
      const { data } = await customerApi.merchantList();
      data.data.forEach((merchant) => {
        if (merchant.is_enabled) {
          this.merchants.push({
            type: merchant.type,
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.type_name} (${merchant.name})`
              : merchant.name,
          });
        }
      });

      if (!this.query.merchant_hashid) {
        const defaultMerchant =
          data.data?.find(
            (merchant) => merchant.type === this.defaultMerchantType
          ) ?? data.data?.find((merchant) => merchant.type === "LINE");
        this.query.merchant_hashid = defaultMerchant.id;
      }

      if (this.merchants.length > 1) {
        this.merchants.unshift({ value: "all", text: "全部" });
        this.merchants.unshift({ value: "cross", text: "跨通路" });
      }
      this.merchant = this.merchants.find(
        (merchant) => merchant.value === this.query.merchant_hashid
      );
    },
    async getCustomerList() {
      try {
        this.isFetchCustomerList = true;
        const { data } = await customerApi.listV2({
          ...this.query,
          merchant_hashid:
            this.query.merchant_hashid === "cross" ||
            this.query.merchant_hashid === "all"
              ? null
              : this.query.merchant_hashid,
          show_cross_merchant:
            this.query.merchant_hashid === "cross" ? true : null,
        });
        this.fields = this.baseFields
          .concat(this.normalizeProfileFeilds())
          .concat({ key: "actions", label: "操作" });
        this.customers = data.data;
        this.totalRows = data.meta.total;
      } catch (e) {
        console.log(e);
      }
      this.isFetchCustomerList = false;
    },
    normalizeProfileFeilds() {
      const customerList = this.getModuleConfig("customer", "customer_list");
      const profileMappings =
        customerList?.find(
          (list) => list.merchant_type.toUpperCase() == this.merchant.type
        )?.member_fields ?? [];
      if (profileMappings) {
        return profileMappings.map((mapping) => {
          const key = mapping.matched_key.split("extra.")[1];
          return {
            key: `profiles.${key}`,
            label: mapping.title,
          };
        });
      } else {
        return [];
      }
    },
    handleChangeMerchant() {
      if (this.query.merchant_hashid === "cross") {
        this.query.is_binded = null;
      }
      this.merchant = this.merchants?.find(
        (merchant) => merchant.value === this.query.merchant_hashid
      );

      this.handlePageReset();
    },
    exportCustomers(type) {
      const merchant_id = this.query.merchant_hashid;
      let baseUrl = `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}/customers/export`;
      if (type === "csv") {
        baseUrl = `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}/customers/export-csv`;
      }
      let params = new URLSearchParams();
      params.append("token", store.state.auth.token);
      if (merchant_id && merchant_id !== "cross" && merchant_id !== "all") {
        params.append("merchant_hashid", merchant_id);
      }
      if (merchant_id === "cross") {
        params.append("show_cross_merchant", true);
      }
      if (this.query.keyword) {
        params.append("keyword", this.query.keyword);
      }
      if (this.query.is_binded) {
        params.append("is_binded", this.query.is_binded);
      }
      let url = `${baseUrl}?${params.toString()}`;

      axios.get(url);

      this.$swal({
        title: "成功",
        text: "可至“我的主控台”查看紀錄及下載檔案",
        showCancelButton: true,
        cancelButtonText: '確認',
        type: "success",
        confirmButtonText: "前往查看",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "MyConsoleDownloadFileList",
            params: { org_code: this.$route.params.org_code },
          });
        }
      });
    },
  },
};
</script>

<style></style>
